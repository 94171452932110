import React from "react"
import { graphql, Link } from "gatsby"

import { StaticImage } from 'gatsby-plugin-image'

import '../../../../node_modules/slick-carousel/slick/slick.css'
import '../../../../node_modules/slick-carousel/slick/slick-theme.css'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import FeatureSide from '../../../components/feature-side'
import FeatureRelated from '../../../components/feature-related'

const Home = ({ data }) => {
  return (
    <Layout>
      <SEO
        pagetitle="感動と勇気をもらえる。スポーツ選手の名言！"
        pagepath="/feature/09/"
      />
      <main>
        <div className="l-feature__mv">
          <div className="l-feature__bg">
            <StaticImage
							src="../../../assets/images/feature/sports01.jpg"
							alt="感動と勇気をもらえる。スポーツ選手の名言！"
						/>
          </div>
          <div className="l-feature__above">
            <h3 className="l-feature__hdg">感動と勇気をもらえる。スポーツ選手の名言！</h3>
          </div>
        </div>
        <div className="l-feature">
          <div className="l-wrap">
            <div className="l-feature__item">
              <div className="l-feature__box">

                <div className="l-feature__main">

                  <div className="l-feature__trunk">
                    <div className="l-feature__paragraph">
                      <h2 className="l-feature__lead">
                        <span>何億人の視線を力に変える。</span>
                        <span>偉大なスポーツ選手はプレッシャーに押しつぶされることなく、力に変えて感動を与えてくれます。</span>
                        <span>
                          アスリートの忍耐と苦労のすえに生まれた名言をご紹介します！<br />
                          きっと、あなたの背中を押してくれるはずです！
                        </span>
                      </h2>
                    </div>

                    <div className="l-feature__paragraph">
                      <div className="l-feature__wrap">
                        <div className="l-feature__num">01.</div>
                        <div className="l-feature__quote">
                          {data.post1.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>ロサンゼルス・エンゼルス所属の大谷翔平選手（2021年時点）が二刀流でMLBを席巻しています。</p>
                        <p>およそ百年前に同じように二刀流で圧倒的な結果を残した伝説的プレイヤーがいました。</p>
                        <p>それがベーブ・ルースです。</p>
                        <p>野球の神様と評されるベーブ・ルースは、</p>
                        <ul>
                          <li>・オールスターゲーム選出 20回</li>
                          <li>・最多得点8回</li>
                          <li>・最多四球11回</li>
                          <li>・リーグ最高出塁率10回</li>
                          <li>・リーグ最高長打率13回</li>
                          <li>・リーグ最高OPS13回</li>
                          <li>・通算サヨナラ本塁打12本</li>
                          <li>・通算満塁本塁打16本</li>
                          <li>・通算ランニング本塁打10本</li>
                        </ul>
                        <p>というとんでもない記録を打ち立てました。</p>
                        <p>この偉業の裏には、彼のこの名言から醸し出される、<span className="marker-b">あきらめない強さ</span>があったのかもしれませんね。</p>

                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">02.</div>
                        <div className="l-feature__quote">
                          {data.post2.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>中華民国籍の日本の元プロ野球選手、王貞治。</p>
                        <p>１３年連続の本塁打王に輝くなど、日本プロ野球界の伝説です。</p>
                        <p>その輝かしいキャリアの裏には、<span className="marker-b">人知れぬ苦悩と努力の日々があった</span>ことでしょう。</p>
                        <p>記録や、華々しいホームランばかりがフィーチャーされがちですが、その背後には苦悩があることを忘れてはいけません。</p>
                        <p>長年、第一線で活躍し続けるとはどんな苦労があったことでしょう。</p>
                        <p>そんな、彼の人生を象徴するような言葉です。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">03.</div>
                        <div className="l-feature__quote">
                          {data.post3.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>2013年5月23日、<span className="marker-b">80歳で3度目のエベレスト登頂に成功</span>した三浦雄一郎。</p>
                        <p>アスリートとしてずっと第一線で活躍してきたイメージがありますが実は、<span className="f-l">65歳の時にメタボ</span>になってしまったことがあります。</p>
                        <p><span className="marker-b">身長164センチなのに体重88キロ、体脂肪率40を超えるまで肥えて</span>しまい、そこから改めて目標を持ち、80歳での偉業に繋がりました。</p>
                        <p><span className="f-l">しっかりとした準備があれば、あなたが何歳であっても目標を持つには遅すぎる事はありません。</span></p>
                        <p>そして勇気が必要と思われる冒険家であっても、準備は非常に重要な要因です。</p>
                        <p>この言葉を、冒険家の言葉とは捉えずに、<span className="marker-y">私たち日常生活に普遍的に通用する真理</span>だと理解しましょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">04.</div>
                        <div className="l-feature__quote">
                          {data.post4.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>日本人メジャーリーガーの草分け的存在、野茂英雄。</p>
                        <p><span className="marker-b">MLBでの日本人選手の活躍の礎になったのは野茂</span>である事は間違いありません。</p>
                        <p>日本でプロとして活躍し、そして世界でもその活躍の場を広げた彼。</p>
                        <p>そんな彼から<span className="f-l">無駄が栄養</span>だと言う発言が出るのが面白いですね。</p>
                        <p>無駄を恐れずいろんなことにチャレンジする事は人生を充実させる大切な要因です。</p>
                        <p><span className="marker-y">『RANGE(レンジ)知識の「幅」が最強の武器になる』</span>と言う本には、<span className="marker-b">様々なジャンルを横断的に経験してきた人ほど成功する確率が上がる</span>ということが様々なデータをもとに展開されています。</p>
                        <p>まさに<span className="f-l">無駄こそが成功の母</span>です。</p>
                        <p>野茂英雄は経験からあるいは直感からその真実に気づいていたのでしょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">05.</div>
                        <div className="l-feature__quote">
                          {data.post5.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>日本が世界に誇るアスリート、イチロー。</p>
                        <p>日本でバッターとしての栄華を極め、その後、MLBでも大活躍しました。</p>
                        <p>彼が特筆すべき点は、その<span className="f-l">現役の長さ</span>でしょう。</p>
                        <p>ただでさえ競争の激しいプロ野球って世界で、長年にわたり第一線で活躍することは並大抵のことではありません。</p>
                        <p>有名な話では、イチローは<span className="marker-b">毎日のルーティンを大切にする</span>ことで知られています。</p>
                        <p><span className="marker-y">朝カレー</span>は日本でもちょっとしたブームになりました。</p>
                        <p>彼独自のルーティンの形成の裏には、工夫と努力があった事は想像に難くありません。</p>
                        <p>無駄なことを考えて無駄なことをするという考え方、何のメリットもない行動をする勇気。</p>
                        <p>もしかしたら、イチローの1番の凄さがそこにあるのかも知れませんね。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">06.</div>
                        <div className="l-feature__quote">
                          {data.post6.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>イチローのこの言葉から、行動する大切さを学びましょう。</p>
                        <p>インターネットの発達により、私たちは簡単に<span className="marker-b">知識にアクセスすることができる</span>ようになりました。</p>
                        <p>世界各国の絶景をインターネット上で見ることができたり、世界各国で起こるニュースを自宅のパソコンから確認することができます。</p>
                        <p>そうは言いつつも、それを<span className="marker-b">知るのと、実際に体験する</span>のでは天と地ほどの差があります。</p>
                        <p>簡単に情報にアクセスできる現代だからこそ、<span className="marker-y">実際に行動することの意義はますます高まっています。</span></p>
                        <p>このような情報化社会が発達することにより、人々はより合理的に行動しようとします。</p>
                        <p><span className="marker-y">簡単に儲かる方法とか、効率的に目標達成する方法みたいなノウハウが流行</span>していますね。</p>
                        <p>こんな時代だからこそ、無駄をしましょう。やってみることでそれが本当に価値あることなのか三面体験することができます。それをのみが本当に財産になります。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">07.</div>
                        <div className="l-feature__quote">
                          {data.post7.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>サッカー史上に残る偉大なプレイヤー、リオネル・メッシ。</p>
                        <p>２００４年、１７歳でFCバルセロナでプロデビューを果たしてから超一流プレイヤーとして活躍する彼には不可能はないと感じさせられます。</p>
                        <p>そんな彼も、<span className="marker-b">順風満帆なサッカー人生を歩んできたわけではありません。</span></p>
                        <p>10歳の頃に発覚した病気、<span className="marker-y">成長ホルモン分泌不全性低身長症</span>により、成長ホルモンの投与なしでは身体が発達しないと診断されてしまいました。</p>
                        <p>そのような逆境にありながらも、周りのサポートも得て、困難に打ち勝ってきました。</p>
                        <p>そんなリオネル・メッシの言葉だからこそ、この言葉は心に染みるものがあります。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">08.</div>
                        <div className="l-feature__quote">
                          {data.post8.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>リオネル・メッシを語るときに欠かせないのが彼のパーソナリティーです。</p>
                        <p>プロサッカー選手と言うお金も地位も名声も手に入れられる職業につきながら、彼は<span className="f-l">謙虚さ</span>を忘れません。</p>
                        <p>他のプレイヤーが、恋愛や散財を重ね、破滅していったり、スキャンダラスな行動に手を染める一方で、彼は<span className="f-l">家族を愛しそしてフットボールを愛してきました。</span></p>
                        <p>彼のパーソナリティーと、そのフットボーラーとしての偉業を重ね合わせると、ますます世界一のアスリートと評される理由がわかります。</p>
                        <p>他のフットボーラーからの圧倒的なリスペクトを得ている点から考えてみても、彼の謙虚さ自己犠牲の気持ちと言うものは計り知れないですね。</p>
                        <p>私たちもリオネル・メッシのように謙虚になりましょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">09.</div>
                        <div className="l-feature__quote">
                          {data.post9.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>一度結果を残したり、評価されたり、地位や名誉を得てしまうと<span className="f-l">へりくだること</span>がしにくくなります。</p>
                        <p>年齢を重ねれば重ねるほど、自分より年下の存在にアドバイスを乞うことは難しくなります。</p>
                        <p>しかし本当に超一流と呼ばれる人たちは、そのような<span className="marker-b">小さなプライドにはとらわれません。</span>相手がどんな存在であろうと自分の成長のためにはアドバイスを求めることができます。</p>
                        <p>これは生涯にわたって成長していく際に大きな資質となります。</p>
                        <p>イチローが評価したこのメジャーリーガー達のように、私たちも常にチャレンジャーそして成長するものとしてのスタンスを忘れないでありたいですね。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">10.</div>
                        <div className="l-feature__quote">
                          {data.post10.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>イチローほど、普段の生活を<span className="f-l">ルーティン化</span>した選手は珍しいのではないでしょうか。</p>
                        <p>そんな地道な姿勢が、彼の<span className="marker-b">長いキャリア・偉大な業績</span>につながりました。</p>
                        <p>世の中の偉人、天才たちと呼ばれる人たちの生活を調査してみると、毎日<span className="f-l">習慣的な活動に従事している</span>割合が高いことがわかります。</p>
                        <p>あなたが憧れるあの人も、もしかしたら<span className="f-l">習慣の奴隷</span>だったかもしれません。</p>
                        <p>なぜか私たちは、特別な偉業をなすためには、特別な行動しなければならないと考えてしまいがちです。</p>
                        <p>真実はちがいます、<span className="marker-y">普段通りのことを普段通りのリズムでやる人間のみが偉業を成し遂げることができます。</span></p>
                        <p>それを証明してくれるようなイチローの言葉ですね。</p>
                      </div>

                    </div>
                  </div>
                  <div className="l-feature__trunk">
                    <FeatureRelated />
                  </div>
                </div>

                <div className="l-feature__data">
                  <FeatureSide data={data} />
                </div>

              </div>

            </div>

          </div>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    post1: allContentfulBlogPost(
      filter: {id: {eq: "cd4f57a0-09f2-5d97-9542-e08c42ecdfda"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post2: allContentfulBlogPost(
      filter: {id: {eq: "87fe18f1-ec48-5cdb-ad13-dc541aece54a"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post3: allContentfulBlogPost(
      filter: {id: {eq: "023e363b-aa11-5eff-8787-1f16be772a33"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post4: allContentfulBlogPost(
      filter: {id: {eq: "2c41387c-8b66-518c-9e89-3a3e7af66dc4"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post5: allContentfulBlogPost(
      filter: {id: {eq: "431eb8fb-3b15-56b3-af98-b8cc1a8ae651"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post6: allContentfulBlogPost(
      filter: {id: {eq: "41003449-440e-5c3b-8067-8927bcdbf6cf"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post7: allContentfulBlogPost(
      filter: {id: {eq: "59d9f062-74b7-55fa-9b74-4a29926bf78a"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post8: allContentfulBlogPost(
      filter: {id: {eq: "9015eb19-4193-5383-bff4-b1dba7963bb0"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post9: allContentfulBlogPost(
      filter: {id: {eq: "d29deb03-39ed-55d5-8dc6-85ddd93a225b"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post10: allContentfulBlogPost(
      filter: {id: {eq: "499da6df-0fab-5f48-afe1-da490faaca3c"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    cate: allContentfulCategory {
      edges {
        node {
          informations {
            informations
          }
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    allCate: allContentfulCategory(
      sort: {fields: sort, order: DESC},
      limit: 20
    ) {
      edges {
        node {
          name
          slug
          id
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    tags: allContentfulTags {
      edges {
        node {
          title
          slug
          id
        }
      }
    }
  }
`

export default Home